import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store/index.js'
// import assetsPath from './util/util.js'
import Mixin from "./mixin/mixin.js"
Vue.mixin(Mixin)// 全局混入

import '@/style/base.css' // global css

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import qualifications from './components/qualifications';
import headertop from './components/headertop';
import headers from './components/header';
import footer from './components/footer';
import goods from './components/goods';
import price from './components/price';




import './page/edit/demo-common/bootstrap.js';
// import elementUI from './page/edit/demo-common/components/ElementUi/index.js';


Vue.use(ElementUI);
Vue.component('qualifications',qualifications)
Vue.component('headertop',headertop)
Vue.component('headers',headers)
Vue.component('footer',footer)
Vue.component('goods',goods)
Vue.component('price',price)

// Vue.use(elementUI);


// Vue.prototype.serve = axios
// Vue.prototype.$qs = qs
// import mixin from './mixin/index'

// 引用API文件
// import Axios from './api/index.js'
// 将API方法绑定到全局
// Vue.prototype.$api = Axios
// var urlhost = window.location;

// var dev=true      
// //    
// if(process.env.NODE_ENV=='development'){  
// 	console.log('开发',process.env)
// 	var serve = axios.create({	  baseURL: process.env.VUE_APP_BASE_URL	})
// 	var urlhost = process.env.VUE_APP_URL_HOST
// 	var wxpayserve = axios.create({	  baseURL: process.env.VUE_APP_WXPAY_SERVE	})
// 	var getwxpayserve = process.env.VUE_APP_WXPAY_SERVE
// 	Vue.prototype.serve = 'http://api.dev.wxkingbos.com';
		   
// }else{  
// 	if(dev){
// 		console.log('测试',process.env)
// 		var serve = axios.create({	  baseURL:'http://api.dev.wxkingbos.com'	})
// 		var urlhost = 'http://api.dev.wxkingbos.com'
// 		var wxpayserve = axios.create({	  baseURL: 'http://pay.dev.wxkingbos.com'	})
// 		var getwxpayserve = 'http://pay.dev.wxkingbos.com'
// 		var urlhost = document.domain 
// 		Vue.prototype.serve = 'http://api.dev.wxkingbos.com';
// 	}else{
// 		console.log('正式',process.env)
// 		var serve = axios.create({	  baseURL: process.env.VUE_APP_BASE_URL	})
// 		var urlhost =document.domain
// 		var wxpayserve = axios.create({	  baseURL: process.env.VUE_APP_WXPAY_SERVE	})
// 		var getwxpayserve = process.env.VUE_APP_WXPAY_SERVE
// 		Vue.prototype.serve = process.env.VUE_APP_BASE_URL;
// 	}
// }


// // window.tid='TYBB'

// // serve.defaults.headers["Content-type"] = "application/x-www-form-urlencoded;charset=UTF-8";

  
// Vue.prototype.$serve = serve;
// Vue.prototype.urlhost = urlhost;
// // Vue.prototype.$urlhost = urlhost;
// Vue.prototype.$wxpayserve = wxpayserve;
// Vue.prototype.getwxpayserve = getwxpayserve;
// Vue.config.productionTip = false


router.beforeEach((to, from, next)=>{
  if(to.meta.title)    document.title = to.meta.title
  next()
})


import mixin from './api/index'
Vue.mixin(mixin);

new Vue({
	router,
	el: '#app',
	axios,
	store,
	mixin,
	render: h => h(App)
});
