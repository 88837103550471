

const app = {
  state: {
    device: 'desktop',
	headeractive:0,//头部状态
	Operational:null,//非经营性参数
	tenantid:'',//租户
	logourl:'',//logo
	aftersalephone:'',//电话
	phone:'',//电话
	qrcodeurl:'',//二维码
	showStock:'',//showStock字段，空和1的时候显示库存，0的时候不显示库存
	configs:'',//商城备案信息
	newslist:[],//新闻列表
	iconpath:'',//头像
	cartnumber:0,//购车数量
	token:'',//token
	cardname:'',
	login:'',//登录参数
	tenantshoppingmallconfig:null,//商城装修配置信息
	gettenant:'',
	showguanggao:false,
	showprice:1,
	goodsvivewtype:1,
	// newslist:[],
  },

  mutations: {
    PUBLIC: (state, roles) => {
		
      state.[roles.type] = roles.res
    },
	TENAN: (state, roles) => {
		// console.log('roles',roles)
		state.logourl = roles.logourl
		state.aftersalephone = roles.aftersalephone.split(",")
		state.phone = roles.phone
		state.qrcodeurl = roles.qrcodeurl
		state.showStock = roles.showStock
		state.configs = roles.configs
		state.configsname = roles.configsname
		
	},

	
  },

  actions: {
    PUBLIC( {commit} , data ) {commit('PUBLIC', data)    },
	tenan( {commit} , data ) {commit('TENAN', data)	},
   
  }
}

export default app
