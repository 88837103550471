<template>
	<div class="header" id="header" v-cloak >
	
	    <div class="w100 search">
	        <!-- pwidth search -->
	        <div class="pwidth relative" v-if="$store.state.app.Operational">
	            <div @click="()=>{$router.push({path:'./index'})}" >
	                <h1 class="relative blod-n w-305 flex" style="display: flex;">
	                    <div class="w-200 h-80 tc flex"><img class=" m-a h100" style="width: auto;" :src="$store.state.app.logourl"></div>
						<!-- <div v-if="!gwcname" class="i-b ">
							<img class="" v-if="$store.state.app.Operational.header.logintext" :src="$store.state.app.Operational.header.logintext" alt="">
						</div>
	                    <p class="c-3 f-24 i-b tr l-h-80" v-if="gwcname&&$store.state.app.Operational">{{$store.state.app.Operational.goodlist.Orderapplication}}</p> -->
	                </h1>
	            </div>
	
	            <div class="searchkuang">
	                <div class="searchinput m-b-5">
	                    <i><img src="../assets/img/search.png" alt=""></i>
	                    <el-input @keyup.enter.native="search(value)" v-model="value" class="c-3" placeholder="请输入通用名称 / 助记码 / 条码 / 品牌"></el-input>
	                    <button @click="search(value)" class="f-14">搜索</button>
	                </div>
	                <ul>
	                    <li>热门搜索:</li>
	                    <li v-for="item in hotsearchHasAjax" @click="search(item.tagname)">{{item.tagname}}</li>
	                </ul>
	            </div>
	
	            <div v-if="$store.state.app.login.cardname" class="w-120 h-40 b-a-00b absolute rad-2 l-h-40 c-00b tc r-30 t-30 r-170 cursor" @click="()=>{$router.push({path:'/cart'})}">
					<el-badge :value="$store.state.app.cartnumber" class="item ">
					  <i class="b2b-gouwuche iconfont m-r-8 f-20" ></i> 
					</el-badge>
					{{$store.state.app.Operational.header.cart}}
	            </div>
	            <div class="QRcode w-70 h-70 tc c-6 f-12">
	                <img class="h-70  w-70 m-b-5" :src="$store.state.app.qrcodeurl" alt="">
					<p>官方公众号</p>
	            </div>
	        </div>
	    </div>
	
		<div class="none">
			<nav :style="{background:$store.state.app.tenantshoppingmallconfig.portaltoptemplate==2?'#ffffff !important':''}">
				<ul >
					<li @click="()=>{$router.push({path:'./index'})}"  class="c-f bold l-h-43 p-t-2">
						
							<em><img src="../assets/img/class.png" alt=""></em>
							所有商品分类
						
					</li>
					
					<li v-for="(item,index) in nav" :class="[$store.state.app.headeractive==item.key?'bold':'',$store.state.app.tenantshoppingmallconfig.portaltoptemplate==2?'classicon':'']" :key="index" @click="btnNav(item.key)" :style="{color:$store.state.app.tenantshoppingmallconfig.portaltoptemplate==2?'#333 ':''}">
						{{item.name}} 
						<i class="w-60 h-4 i-b" :class="$store.state.app.tenantshoppingmallconfig.portaltoptemplate==2?'--bg':'bg-f'"  v-if="$store.state.app.headeractive==item.key"></i>
					</li>
		
				</ul>
		
			</nav>
		</div>
		
		<!-- <customerservice/> -->
	</div>
</template>

<script>
	// import customerservice from './customerservice.vue';
	export default{
		// components:{customerservice},
		data(){
			return{
				Operational:'',
				headeractive: 0,
				nav: [{name: "首页",key: 0}, { // name: "活动专区"
				}, { // name: "新品发现"
				}, 
				{name: "企业介绍",key: 1}, 
				{name: "联系我们",key: 2},
				{name: "批检报告",key: 3}],
				value:this.$route.query.query,//输入的搜索内容
				hotsearchHasAjax: [],
				gwcname:this.$route.path=='/cart'?true:false //显示购物车标题
			}
		},
		
		created: function created() {
			console.log('query',this.$router,this.$route.path,['index','newslist'].includes(this.$route.path))
			
			if(!['/index','/newslist'].includes(this.$route.path))if(!this.$store.state.app.token) return  this.$router.push({path:"/index"}),this.$message({showClose: true,message: '请先登录',type: 'warning'});
			this.getTenantid(()=>{
					this.getRichtext()
			})
			if(this.$store.state.app.token) this.cartNumber(); //查询购物车数量
			var hotsearchHasAjax = sessionStorage.getItem('hotsearchHasAjax');
			if (hotsearchHasAjax) this.hotsearchHasAjax = hotsearchHasAjax ? JSON.parse(hotsearchHasAjax) : '';
			if (hotsearchHasAjax==null)this.HotSearch()
			var headeractive = sessionStorage.getItem('headerNavs') ? sessionStorage.getItem('headerNavs') : 0;
			// var headeractive = sessionStorage.getItem('headerNavs') ? sessionStorage.getItem('headerNavs') : 0;
			this.$store.dispatch('PUBLIC',{type:'headeractive',res:headeractive})
			if(this.$route.path=='/cart')this.$store.dispatch('PUBLIC',{type:'headeractive',res:0})
		},
		
		methods: {
			
			// getUrlParam(name) {
			// 	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
			// 	var r = window.location.search.substr(1).match(reg); //匹配目标参数
			// 	return r != null ? decodeURI(r[2]) : null;
			// },// 获取整个参数字符串（无？符号）
			
			HotSearch() {
				// var hotsearchHasAjax = sessionStorage.getItem('hotsearchHasAjax');
					// console.log('hotsearchHasAjax999',hotsearchHasAjax )
				// if (hotsearchHasAjax == null || !hotsearchHasAjax || hotsearchHasAjax == 'undefined') {
					this.getTenantid(res=>{
						this.requset('/b2bgoods/getgoodstag', {}).then(res=> {
							this.hotsearchHasAjax =  res.data;
							if( res.data) sessionStorage.setItem('hotsearchHasAjax', JSON.stringify( res.data)); 
						})
					})
				// }
			},
			
			// 选中的nav状态
			btnNav(index) {
				this.headeractive = index;
				sessionStorage.setItem('headerNavs', index);
				this.$store.dispatch('PUBLIC',{type:'headeractive',res:index})
				this.$router.push({path:"/index"})
				let newslist=this.$store.state.app.newslist
				if(index==1)this.$router.push({path:'/newslist',query:{showdetail:true,detailid:newslist.QYJS.length?newslist.QYJS[0].id:''}})
				if(index==2)this.$router.push({path:'/contact',query:{showdetail:true,detailid:newslist.LXWM.length?newslist.LXWM[0].id:''}})
				this.Backtop()
			},
			
			// 点击搜索
			search: function search(item) {
				var query = '';
				if (item == null) {
					query = "";
				} else {query = item;}
				sessionStorage.setItem('headerNavs', 0);
				sessionStorage.setItem('pageno', 1);
				this.value=query//输入的搜索内容
				if(!this.$store.state.app.token) return this.$message({showClose: true,message: '请先登录',type: 'warning'});
				this.$router.push({path:"/goodslist",query:{query:query}})
				this.$emit('search')
				// window.location.href = "/view/goods/goodslist.html?query=" + query;
			},
		}
	}
</script>

<style>
	.el-badge__content.is-fixed{top: 8px;right: 20px;}
	.el-badge__content{background: #FF8A00;}
</style>
