<template>
	<div>
		<div class="loginpub">
			<ul class="towmd logh3">
				<li><h3>会员登录</h3></li>
				<li class="mright"></li>
			</ul>
			<div class="loginpubbox"></div>
		</div>
		
		<div class="tisi"></div>
		<div class="addgoods Collection"><img src="../assets/img/succ.png" alt=""></div>
		<div class="addgoods Collection_n"><img src="../assets/img/error.png" alt=""></div>
		

		<!-- <div id="">
			<div class="fix r-2 t-0 b-0 m-a cursor h-110 shadow-015 rad-2">
				<div class="w-55 h-55 bg-f telphones tc rad-b-5 border-t" id="phone">
					<img class="m-t-15 w-24 h-24 zxdhimg" src="../assets/img/kf.png" alt="">
					<div class="zxdh none p-10 f-12  rad-t-5">
						<p class="f-12">联系</p>
						<p class="f-12">电话</p>
					</div>
					<div class="telphone t-0 tc c-f  absolute r-70">
						<div class=" f-14 bg-f w-150 h-50 m-a relative p-l-15 tr p-r-5 p-t-2">
							<span class="iconfont c-b14e absolute l-10 top-7 f-26">&#xe68d;</span>
							<p class="c-b14e f-18 family-ro">{{$store.state.app.phone}}</p>
							<p class="c-b14e">客服热线</p>
						</div>
					</div>
				</div>
				<div class="w-55 h-55 bg-f telphones tc rad-b-5 border-t relative" >
					<img class="w-24 h-24 m-t-17 zxdhimg" src="../assets/img/QQ.png" alt="">
					
					
					<div class="zxdh none  f-12  rad-t-5">
						<a :href="'tencent://message/?uin='+qq.value+'&Site=&menu=yes'">
							 <p class="f-14 l-h-55 c-f" >QQ</p>
						</a>
					</div>
					<div class="telphone t-0 tc c-f  absolute r-70">
						<div class=" f-14 bg-f w-150 h-50 m-a relative p-l-15 tl p-r-5 p-t-2 l-h-50">

							<span class="iconfont c-b14e absolute l-10 top-3 f-26">&#xe68c;</span>
							<p class="c-3 f-18 family-ro m-l-20">{{qq.value}}</p>
						</div>
					</div>
				</div>
				<div class="w-55 h-55 bg-f wx tc rad-b-5 border-t relative wx" style="padding: 0px;">
					<img class="w-24 h-24 m-t-17 zxdhimg" src="../assets/img/wx.png" alt="">
					<div class="zxdh none  f-12  rad-t-5">
						<p class="f-14 l-h-55 c-f">微信</p>
					</div>
					<div class="ewm t-0 tc c-f  absolute r-70 w-170 h-190 ">
						<div class=" w-170 h-190 p-t-20">
							<img class="w-130 h-130  m-b-10 " :src="wx.value||$store.state.app.qrcodeurl" alt="">
							<p class="h-10">联系客服</p>
						</div>
					</div>
				</div>
				<div class="w-55 h-55 bg-f   tc tops rad-5" id="return_top" @click="Backtop">
					<img class="w-24 h-24 m-t-17" src="../assets/img/top1.png" alt="">
					<div class="backtop none rad-b-5 p-10 f-12">
						<p class="f-12">回到</p>
						<p class="f-12">顶部</p>
					</div>
				</div>
			</div>
		</div> -->
		

		<div class="footer c-3 bg-f" id="footer" v-cloak>
			<div class="footerTOP" v-if="pagefooting!=1">
				<div class="footer_contenr">
					<ul>
						<li>
		
							<i class="iconfont classicon f-40 l-h-50 b2b-a-tuoyuan2"></i>
							<div>
								<h3>国家监管</h3>
								<p>国家食品药品监督管理局认证</p>
							</div>
						</li>
						<li>
							<i class="iconfont classicon f-40 l-h-50 b2b-a-tuoyuan2kaobei"></i>
							<div>
								<h3>正品保障</h3>
								<p>严格质量把关100%正品，值得信赖</p>
							</div>
						</li>
						<li v-if="$store.state.app.Operational&&!$store.state.app.Operational.index.Sloganicon">
							<i class="iconfont classicon f-40 l-h-50 b2b-a-tuoyuan2kaobei2"></i>
							<div>
								<h3>{{$store.state.app.Operational.index.Slogan}}</h3>
								<p>{{$store.state.app.Operational.index.Sloganconten}}</p>
							</div>
						</li>
						<li>
							<i class="iconfont classicon f-40 l-h-50  b2b-a-tuoyuan2kaobei7"></i>
							<div>
								<h3>专业服务</h3>
								<p>1对1客服，为你排忧解难</p>
							</div>
						</li>
					</ul>
		
				</div>
			</div>
		
			<div class="pwidth footerbuttom">
				<div class="footerbuttom_left ">
					<dl>
						<dt>关于我们</dt>
						<dd v-for="(item,index) in newslist.GYWM" class="cursor" :key="index" @click="detailnews(item.id,0,index)">{{item.title}}
						</dd>
					</dl>
					<dl>
						<dt>新手帮助</dt>
						<dd v-for="(item,index) in newslist.XSBZ" class="cursor" :key="index" @click="detailnews(item.id,1,index)">{{item.title}}
						</dd>
					</dl>
					<dl>
						<dt>售后服务</dt>
						<dd v-for="(item,index) in newslist.SHFW" class="cursor" :key="index" @click="detailnews(item.id,2,index)">{{item.title}}
						</dd>
					</dl>
					<dl v-if="$store.state.app.Operational&&$store.state.app.Operational.index.showpaytype=='true'">
						<dt>{{$store.state.app.Operational.index.paytype}}</dt>
						<dd v-for="(item,index) in newslist.ZFFS" class="cursor" :key="index" @click="detailnews(item.id,3,index)">{{item.title}}
						</dd>
					</dl>
				</div>
				<div class="flex f-14 c-6 p-t-30">
					<div class="tc m-r-30">
						<img class="w-105 h-105 m-b-5" :src="$store.state.app.qrcodeurl" alt="">
							<p>扫码关注公众号</p>
					</div>
					<div class="tc m-r-30" v-if="$store.state.app.gettenant.b2bwshoppingqrcode">
						<img class="w-105 h-105 m-b-5" :src="$store.state.app.gettenant.b2bwshoppingqrcode" alt="">
							<p>小程序商城</p>
					</div>
					<div class="tc">
						<img class="w-105 h-105 m-b-5" :src="$store.state.app.gettenant.contactqrcodeurl" alt="">
							<p>联系我们</p>
					</div>
				</div>
				
		
			</div>
		
			
			<qualifications  class="bg-4e5"/>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				article: ['HYXW', 'FLFG', 'ZSFX', 'GYWM', 'XSBZ', 'SHFW', 'ZFFS', 'QYJS', 'LXWM','SCXW'],
				newslist:  {GYWM: [],XSBZ: [],ZFFS:[]}, 
				wx:'',
				qq:'',
				pagefooting:sessionStorage.getItem('pagefooting')
			}
		},
		
		created() {
			this.getTenantid(()=>{
					this.getRichtext()
			})
			console.log('this.$store.state.app',this.$store.state.app)
		},
		
		mounted() {
			var configs=this.$store.state.app.configs
			// console.log('configs',configs)
			if(configs)configs.map(res=>{
				if(res.id==128)this.wx=res
				if(res.id==127)this.qq=res
				
			})
			this.loading()
		},
		
		methods: {
			loading(){
				var _this=this
				
				this.qrcodeurl = sessionStorage.getItem('qrcodeurl');
				var newslist = sessionStorage.getItem('newslist');
				if (newslist&&newslist!='undefined') {
					this.newslist = JSON.parse(newslist);
					this.$store.dispatch('PUBLIC',{type:'newslist',res:this.newslist})
					// if (window.homes) homes.Articletitle_centent = JSON.parse(newslist);
					// if (window.introduce) introduce.Articletitle_centent = JSON.parse(newslist);
					// if (window.about) about.Articletitle_centent = JSON.parse(newslist);
				} else {
						this.Articletitle()
					// this.getTenantid(()=> {
					// })
				}
			},
			
			// 获取文章的数据POST
			Articletitle() {
				this.getnewslist().then(res=>{this.newslist = res})
				
			},
			
			detailnews: function detailnews(id,k,i) {
				document.documentElement.scrollTop=0
				// this.$router.push({path:'/newslist',query:{showdetail:true,detailid:id}})
				this.$parent.headeractive=2
				this.$emit('active',2)
					this.$store.dispatch('PUBLIC',{type:'headeractive',res:2})
				this.$router.push({path:'/index',query:{headeractive:2,showdetail:true,detailid:id,check:'tb'+k+'-'+i}})
				// window.location.href = '/view/news/newsDetail.html?id=' + id;
			}
		}
	}
</script>

<style>
</style>
