/**
 * 全局混入型
 */

// import util from '../../public/config.js'

// let origin=window.location.origin  

module.exports = {
	data() {
		return {
			color:colorfun(),
			tenantid:sessionStorage.getItem('tenantid')||window.tid,
			erpcustno:sessionStorage.getItem('erpcustno'),
			creditdata:sessionStorage.getItem('credit'),
			goodsview:{
				1:{
					tymc:'通用名称',tymcval:'goodsname2',tymcval1:'goodsname',
					zczh:'批准文号',zczhval:'approvalno',
					xh:'品牌',xhval:'brandname',
					gg:'产品规格',ggval:'spec', 
					bzgg:"剂型",bzggval:'dosage',
				},
				2:{
					tymc:'通用名称',tymcval:'goodsname2',tymcval1:'goodsname',
					zczh:'批准文号',zczhval:'approvalno',
					xh:'品牌',xhval:'brandname',
					gg:'产品规格',ggval:'spec', 
					bzgg:"剂型",bzggval:'dosage',
				},
				3:{
					tymc:'商品名称',tymcval:'goodsname',
					zczh:'注册证号',zczhval:'approvalno',
					xh:'型号',xhval:'prdmodel',
					gg:'规格',ggval:'spec',
					bzgg:"包装规格",bzggval:'cjzpkname',
				}
			}
		}
	},
}

// function colorfun(){
// 	switch (origin){
// 		case 'http://192.168.1.146:8888': return 'TYBB'; break;
// 		case 'http://b2bv4.dev.wxkingbos.com': return 'TYBB'; break;
		
// 	}
// }