import { render, staticRenderFns } from "./customerservice.vue?vue&type=template&id=19c32961&"
import script from "./customerservice.vue?vue&type=script&lang=js&"
export * from "./customerservice.vue?vue&type=script&lang=js&"
import style0 from "./customerservice.vue?vue&type=style&index=0&lang=css&"
import style1 from "./customerservice.vue?vue&type=style&index=1&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports