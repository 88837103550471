import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
	
	{path: '/login',component: () => import('@/page/login/login'),meta: { title:"登录"}},
	{path: '/index',component: () => import('@/page/index/indexpublic'),meta: { title:"首页"}},
	{path: '/newslist',component: () => import('@/page/index/newslist'),meta: { title:"新闻中心"}},
	{path: '/contact',component: () => import('@/page/index/contact'),meta: { title:"新闻中心"}},
	{path: '/goodslist',component: () => import('@/page/goods/goodslist'),meta: { title:"商品列表",keepAlive: true}},
	{path: '/register',component: () => import('@/page/login/register'),meta: { title:"注册"}},
	{path: '/detail',component: () => import('@/page/goods/detail'),meta: { title:"商品详情"}},
	{path: '/cart',component: () => import('@/page/goods/cart'),meta: { title:"购物车"}},
	{path: '/submitted',component: () => import('@/page/goods/submitted'),meta: { title:"购物车",keepAlive: false}},
	{path: '/member',component: () => import('@/page/member/memberindex'),meta: { title:"个人中心"}},
	{path: '/edit',component: () => import('@/page/edit/editor/Editor.vue'),meta: { title:"编辑"}},
	{path: '/text',component: () => import('@/page/index/text.vue'),meta: { title:"编辑"}},
	{path: '/bigsite',component: () => import('@/page/index/bigsite.vue'),meta: { title:"大专场"}},
	{path: '/*',component: () => import('@/page/index/indexpublic'),meta: { title:"商城"}},
]


export default new Router({
	// mode: 'history',
	
	
	
	//后端支持可开
	// scrollBehavior: () => ({ y: 0 }),
	routes
})


export {
  routes
};

