import Vue from 'vue'
import Router from 'vue-router';
import Axios from 'axios'
import md5 from 'md5'
import qs from 'qs';
import axios from 'axios'
var timestep = new Date().getTime();
var tenantid ="KBNC"
var dev=0 //是否测试 
var serve,urlhost,wxpayserve,getwxpayserve
import api from './index.js'

// let serve =dev? axios.create({baseURL:'http://api.dev.wxkingbos.com'}):axios.create({baseURL: 'http://api.dev.wxkingbos.com'	})

if(process.env.NODE_ENV=='development'){  
	console.log('开发',process.env)
	serve = axios.create({	  baseURL: process.env.VUE_APP_BASE_URL	})
	urlhost = process.env.VUE_APP_URL_HOST
	wxpayserve = axios.create({	  baseURL: process.env.VUE_APP_WXPAY_SERVE	})
	getwxpayserve = process.env.VUE_APP_WXPAY_SERVE
	Vue.prototype.serve = 'http://api.dev.wxkingbos.com';
		   
}else{  
	if(dev){
		console.log('测试',process.env)
		serve = axios.create({	  baseURL:'http://api.dev.wxkingbos.com'	})
		urlhost = 'http://api.dev.wxkingbos.com'
		wxpayserve = axios.create({	  baseURL: 'http://pay.dev.wxkingbos.com'	})
		getwxpayserve = 'http://pay.dev.wxkingbos.com'
		urlhost = document.domain 
		Vue.prototype.serve = 'http://api.dev.wxkingbos.com';
	}else{
		console.log('正式',process.env)
		serve = axios.create({	  baseURL: process.env.VUE_APP_BASE_URL	})
		urlhost =document.domain
		wxpayserve = axios.create({	  baseURL: process.env.VUE_APP_WXPAY_SERVE	})
		getwxpayserve = process.env.VUE_APP_WXPAY_SERVE
		Vue.prototype.serve = process.env.VUE_APP_BASE_URL;
	}
}

Vue.prototype.$serve = serve;
Vue.prototype.urlhost = urlhost;
// Vue.prototype.$urlhost = urlhost;
Vue.prototype.$wxpayserve = wxpayserve;
Vue.prototype.getwxpayserve = getwxpayserve;
Vue.config.productionTip = false


console.log('环境env',process.env)
const platformid=3//平台id

const mixin = {
	
	data(){
		return{
			loginouttime:true,
		}
	},
	mounted(){

	},
	
	methods: {
		
		
		// 获取富文本  GET 开发完成 /tenant/gettext
		getRichtext(){
			var Operational = sessionStorage.getItem('Operational');
			// console.log(Operational)
			if(Operational){ 
				this.$store.dispatch('PUBLIC',{type:'Operational',res:JSON.parse(Operational) })
			}else{
				this.requset('/tenant/gettext',{url:this.urlhost},{met:'get'}).then(res=>{
					sessionStorage.setItem('Operational', JSON.stringify(res)); //经营性
					this.$store.dispatch('PUBLIC',{type:'Operational',res })
				})
			}
		},
		
		
		getSignStr(url, tenantid, timestep) {
			var url = url.split('/');
			url = url[url.length - 1];
			return "kingbos" + tenantid + timestep + url;
		},
		
		// 获取租户
		getTenantid(callback){
			var self = this;
			var tenantid = sessionStorage.getItem('tenantid');
			var theme = sessionStorage.getItem('theme');
			var b2bgmallconfig = sessionStorage.getItem('tenantshoppingmallconfig');
			var gettenant = sessionStorage.getItem('gettenant');
			
			
			var url = '/tenant/gettenant';
			theme?'':theme='#00b14e'
			theme=='undefined'?theme='#00b14e':''
			

			console.log('查询租户tenantid',tenantid);
			// 已获取到租户
			if(tenantid=='undefined')sessionStorage.removeItem('tenantid')
			if (tenantid != null) {
				this.getsessionStorage();
				this.getnewslist(); 
				document.getElementsByTagName('body')[0].style.setProperty('--selectedColor', theme)
				console.log('查询租户tenantid',sessionStorage.getItem('showprice'));
			 
				this.$store.dispatch('PUBLIC',{type:'goodsvivewtype',res:sessionStorage.getItem('goodsvivewtype')*1})
				this.$store.dispatch('PUBLIC',{type:'showprice',res:sessionStorage.getItem('showprice')*1}) 
				this.$store.dispatch('PUBLIC',{type:'gettenant',res:JSON.parse(gettenant)}) 
				this.$store.dispatch('PUBLIC',{type:'tenantshoppingmallconfig', res:JSON.parse(b2bgmallconfig)})
				callback(); 
				return;
			}			
			var data= {url: this.urlhost,tenantid: tenantid||'KBNC',}
			
			this.requset(url,data).then(res=>{
				var data=res.data
				if (res.status == 'success') {
					tenantid = data.tenantid;
					window.tid=data.tenantid
					sessionStorage.setItem('tenantid', data.tenantid); //租户
					sessionStorage.setItem('logourl', data.logourl); //logo
					sessionStorage.setItem('aftersalephone', data.aftersalephone); //电话
					sessionStorage.setItem('phone', data.phone); //电话
					sessionStorage.setItem('qrcodeurl', data.qrcodeurl); //二维码
					sessionStorage.setItem('showStock', data.showStock); //showStock字段，空和1的时候显示库存，0的时候不显示库存
					sessionStorage.setItem('theme', data.theme); //主题色
					sessionStorage.setItem('gettenant',JSON.stringify(data)); 
					
					sessionStorage.setItem('theme',data.tenantshoppingmallshowconfig.subjectcolor); //主题色
					sessionStorage.setItem('tenantshoppingmallconfig', JSON.stringify(data.tenantshoppingmallshowconfig));//装修配置
					sessionStorage.setItem('goodsvivewtype',data.tenantshoppingmallshowconfig.goodsvivewtype);//装修配置
					
					document.getElementsByTagName('body')[0].style.setProperty('--selectedColor',data.tenantshoppingmallshowconfig.subjectcolor)
					data.configs.filter(res=>{
						data.configs.filter(res1=>{
							if(res.id==97&&res1.id==98)res.href=res1.value
						})
						if(res.id==169){
							sessionStorage.setItem('showprice', res.value );
							this.$store.dispatch('showprice',res.value) 
						}
						if(res.id==170)sessionStorage.setItem('contact', res.value );
						if(res.id==171)sessionStorage.setItem('pagefooting', res.value );
						if(res.id==31)sessionStorage.setItem('allowPlaceAnOrder', res.value ); 
					})
					
					this.$store.dispatch('PUBLIC',{type:'tenantshoppingmallconfig',res:data.tenantshoppingmallshowconfig })
					this.$store.dispatch('PUBLIC',{type:'subjectcolor',res:data.tenantshoppingmallshowconfig.subjectcolor })
					this.$store.dispatch('PUBLIC',{type:'b2bwshoppingqrcode',res:data.tenantshoppingmallshowconfig.b2bwshoppingqrcode })
					this.$store.dispatch('PUBLIC',{type:'contactqrcodeurl',res:data.tenantshoppingmallshowconfig.contactqrcodeurl })
					this.$store.dispatch('PUBLIC',{type:'gettenant',res:data.tenantshoppingmallshowconfig.gettenant })
					
					this.$store.dispatch('tenan',data)
					sessionStorage.setItem('configs', JSON.stringify(data.configs) ); //商城备案信息
					sessionStorage.setItem('getTenantid', JSON.stringify(data) ); //商城备案信息
					// this.HotSearch()
					// this.getRichtext()
					this.getnewslist()
					callback()
				}
				
			})
		},
		
		
		// 获取缓存
		getsessionStorage(){
		
			var getTenantid=sessionStorage.getItem('getTenantid')
			var login=sessionStorage.getItem('login')
			var iconpath=sessionStorage.getItem('iconpath')
			// console.log(JSON.parse(getTenantid))
			if(getTenantid)this.$store.dispatch('tenan',JSON.parse(getTenantid))
			if(login)this.$store.dispatch('PUBLIC',{type:'token',res:JSON.parse(login).token})
			if(iconpath)this.$store.dispatch('PUBLIC',{type:'iconpath',res:iconpath})
			if(login)this.$store.dispatch('PUBLIC',{type:'login',res:JSON.parse(login)})
		},
		
		
		
		

		
		// 请求登录封装
		logins(url, data, type) {
			var type = type ? type : 'post';
			var timestep = new Date().getTime();
			var tenantid = sessionStorage.getItem('tenantid');
			var signStr = this.getSignStr(url, tenantid, timestep); // data = { ...data, timestep, sign: hex_md5(signStr), tenantid: tid }
		
			data['timestep'] = timestep;
			data['sign'] = md5(signStr);
			data['tenantid'] = tenantid;

			return new Promise((resolve, reject) => {
				this.requset(url,data).then(res=>{
					if (res.status == 'success') {
						var result=res.data
						sessionStorage.setItem('cardname', result.cardname);
						sessionStorage.setItem('cardid', result.cardid);
						sessionStorage.setItem('companyname', result.companyname);
						sessionStorage.setItem('companytype', result.companytype);
						sessionStorage.setItem('openid', result.openid);
						sessionStorage.setItem('token', result.token);
						sessionStorage.setItem('custno', result.erpcustno);
						sessionStorage.setItem('orgno', result.selerporgno);
						sessionStorage.setItem('ismainadmin', result.ismainadmin); // sessionStorage.setItem('userid', result.salesmannum);
						sessionStorage.setItem('erpcustno', result.erpcustno);
						sessionStorage.setItem('salesmannum', result.salesmannum||''); //业务员编码
						sessionStorage.setItem('issaleman', result.issaleman); //0客户 1业务员
						sessionStorage.setItem('companycode', result.companycode);
						sessionStorage.setItem('cmysalesmannum ', result.cmysalesmannum); //公司业务员编码
						sessionStorage.setItem('orgid', result.orgid); //公司业务员编码
						sessionStorage.setItem('salegroups', JSON.stringify(result.salegroups)); //公司业务员编码
							
						
						sessionStorage.setItem('headerNavs', 0);
						if (result.iconpath == null) {
							sessionStorage.setItem('iconpath', '');
						} else {
							sessionStorage.setItem('iconpath', result.iconpath);
						}
						sessionStorage.setItem('login',JSON.stringify(result) );
						this.$store.dispatch('PUBLIC',{type:'iconpath',res:result.iconpath})
						this.$store.dispatch('PUBLIC',{type:'token',res:result.token})
						this.$store.dispatch('PUBLIC',{type:'login',res:result})
						console.log(result)
						// if (result.issaleman == 0)if (result.erpcustno) credit(result.erpcustno, function() {}); //查信用额度
						if (result.issaleman == 1) {
							resolve(res)
						} else {
							this.$notify({ title: '成功', message: '登录成功', type: 'success',duration:1000 });
							setTimeout(()=>{this.$router.push({path:'/index'})}, 1000);
						
						}
						this.cartNumber(); //查询购物车数量
					}else{
						resolve(res)
					}
					
				})
			})
		},
		
		
		//这个是 格式的编码  请求类型（Content-Type）😂application/x-www-form-urlencoded
		requset(url, mixin,mold) {
			// var params = this.$route.query; //url 参数
		
			
			var tid = sessionStorage.getItem('tenantid');
			var cardid = sessionStorage.getItem('cardid');
			var openid = sessionStorage.getItem('openid');
			var token = sessionStorage.getItem('token');
			var erpcustno = sessionStorage.getItem('erpcustno');
			var orgid = sessionStorage.getItem('orgid')||'';
			var salegroups=sessionStorage.getItem('salegroups')
			if(salegroups&&salegroups!='undefined'){
				var salegroups=JSON.parse(sessionStorage.getItem('salegroups'))
				console.log(salegroups)
				var salesalegroupno=salegroups[0].groupno
				var salesalegroupname=salegroups[0].groupname
			}
			
			
			var salesmannum = sessionStorage.getItem('salesmannum')||'';
			
			 salesmannum = salesmannum==null?'':salesmannum;
			let timestep = new Date().getTime()
			var tenantid=mixin?mixin.tenantid?mixin.tenantid:tid:tid
			let sign=md5(this.getSignStr(url, tenantid, timestep))
			let $serve=serve
			return new Promise((resolve, reject) => {
				var reqdata={salesalegroupname,salesalegroupno,orgid,openid,sign,timestep,tenantid,cardid,b2bcardid:cardid,platformid: platformid,erpcustno,salesmannum,token,...mixin}
				var data=qs.stringify(reqdata)
				var ContentType='application/x-www-form-urlencoded;charset=UTF-8'
				
				if(mold)if(mold.met=='json') {
					ContentType='application/json;charset=UTF-8'
					data=reqdata
				}

				if(!mold||mold&&mold.met=='json'){
					
					$serve({
						url: url,
						method:'post',
						responseType:"json",
						data:data,
						headers:{'Content-Type':ContentType},
					}).then(res => {
						if(res.data.status=='loginout'){
							this.authorization(res.data)
							resolve(res.data)
						}else{
							resolve(res.data)
						}	
					}).catch(err => {reject(err)})
				}
				
				if(mold&&mold.met=='pay'){
					
					if(mixin.paytype=='alipay') {
						window.open(this.getwxpayserve+url+'?'+data,"_back");
						resolve()
						return
					}
				
					this.$wxpayserve({
						url: url,
						method:'post',
						responseType:"json",
						data:data,
						headers:{'Content-Type':ContentType},
					}).then(res=>{
						if(res.data.status=='loginout'){
							this.authorization(res.data)
						}else{
							resolve(res.data)
						}	
					}).catch(err => {reject(err)})
				}
				
				
				if(mold&&mold.met=='get'){
					$serve({
						url: url,
						method:'get',
						responseType:"json",
						params:reqdata,
						headers:{'Content-Type':ContentType},
					}).then(res => {resolve(res.data)})
					.catch(err => {reject(err)})
				}
				
				
				
			})
		},
		
		
		
		
		//退出登录
		logout(){
			var str = window.location.href;
			sessionStorage.removeItem('login');
			sessionStorage.removeItem('openid');
			sessionStorage.removeItem('orgno');
			sessionStorage.removeItem('cardname');
			sessionStorage.removeItem('companyname');
			sessionStorage.removeItem('token'); // sessionStorage.removeItem('logourl');
			sessionStorage.removeItem('cardid'); // sessionStorage.setItem('phone', '');
			sessionStorage.removeItem('salegroups'); // sessionStorage.setItem('phone', '');
			sessionStorage.removeItem('smalllist'); // sessionStorage.setItem('phone', '');

			sessionStorage.removeItem('iconpath');
			sessionStorage.removeItem('paytype');
			sessionStorage.removeItem('companycode');
			sessionStorage.removeItem('erpcustno');
			sessionStorage.removeItem('querymycustomer');
			sessionStorage.removeItem('credit'); // var openid = sessionStorage.getItem('openid');
			sessionStorage.setItem('cartnumber', 0);

			this.$store.dispatch('PUBLIC',{type:'cartnumber',res:0})
			this.$store.dispatch('PUBLIC',{type:'token',res:''})
			this.$store.dispatch('PUBLIC',{type:'iconpath',res:''})
			this.$store.dispatch('PUBLIC',{type:'login',res:''})
			
			var path=this.$route.path
			
			if(path=='/member'||path=='/submitted'||path=='/cart'||path=='/goodslist' )window.location.href='/#/login'
			// this.$router.push({path:'login'})
			
			
			
			
		},
		
		
		
		/*加入收藏商品*/
		addCollections(goodsid, type) {
			var types = !type ? "save" : "cancel";
			var url='/goodsfavorites/' + types
			var  data= {typeid: 2,goodsid: goodsid,}
			
			return new Promise((resolve, reject) => {
				if (!this.checklogin()) {resolve();return}  //检查是否登录
				this.requset(url,data).then(res=>{
					if (res.status == 'fail') {
						// Collection(res.message, 0);
						reject(res)
					}	
					if (res.status == 'success') {
						if (types == 'save') {
							// Collection(res.message, 1);
							 this.$notify({title: '提示',message: '收藏成功',type: 'success',duration:2000});
							 resolve()
						} else {
							this.$notify({title: '提示',message: '已取消收藏',type: 'success',duration:2000});
							 resolve()
							// Collection('已取消收藏', 0);
						}
					}
				})
			})
		},
		
		
		
		
		
		// 添加到购物车POST 加入购物车
		addcar(item, batchno, qty) {
			if (!this.checklogin()) return ; //检查是否登录
			var data={
				goodsid: item.id,
				goodsno: item.goodsno,
				batchno: batchno||'defaultbno',
				qty:  qty,
				batchnosalegroupname:item.batchnosalegroupname,
				batchnosalegroupno:item.batchnosalegroupno
			}
			console.log(999999999999,data)
			return new Promise( (resolve, reject)=>{
				
				this.requset('/b2bshoppingcart/additem',data).then(res=>{
					if (res.status == 'success'){
						this.$notify({title: '提示',message: '加入成功',type: 'success',duration:2000});
						this.cartNumber(); //查询购物车数量
						resolve(res)
					}else{
						this.$message.error(res.message);
						resolve(res)
					}
					
				})
				
			})
			
		},
		
		checklogin(){
			if(!this.$store.state.app.token){
				// this.$notify({title: '提示',message: '您还没有登录',type: 'error',duration:800});
				 this.$confirm('您还没有登录，前往登录？', '提示', {
				          distinguishCancelAndClose: true,
				          confirmButtonText: '去登陆',
				          cancelButtonText: '取消',
						  customClass:this.color,
				        }).then(() => {
							this.$router.push({path:'/login'})
				          })
				          .catch(action => {
				           
				          });
				
				return false
			}else{
				return true
			}
		},
		
		// 获取购物车数量
		cartNumber() {
			var url = '/b2bmembercard/numbers';
			if(!this.$store.state.app.token)return
			this.requset(url, {}).then((data)=>{
				var data = data.data;
				data = Number(data.cartnumber);
				sessionStorage.setItem('cartnumber', data);
				this.$store.dispatch('PUBLIC',{type:'cartnumber',res:data})
				return false;
			});
		},
		
		// // 获取各种数量
		// getnumber: function getnumber() {
		// 	var data = {
		// 		openid: openid,
		// 		pageno: this.pageno,
		// 		pagesize: 10,
		// 	};
		// 	this.requset('/b2bmembercard/numbers', data).then(data=>{
		// 			if (data.status == 'success')this.getnumberArr = data.data;
		// 	});
		// },
		
		// 上传封装 formData
		uploadrequset(url, formData, type) {
			var type = type ? type : 'post';
			var timestep = new Date().getTime();
			var token = sessionStorage.getItem('token');
			var tenantid = sessionStorage.getItem('tenantid');
			var cardid = sessionStorage.getItem('cardid');
			var signStr = this.getSignStr(url, tenantid, timestep);

			formData.append('timestep', timestep);
			formData.append('sign', md5(signStr));
			formData.append('tenantid', tenantid);
			formData.append('cardid', cardid);
			formData.append('token', token);
			return new Promise((resolve, reject)=>{
				this.$serve({
					url: url,
					data: formData,
					headers:{'Content-Type':'multipart/form-data'},
					method:type
				}).then(res=>{resolve(res.data);})
			});
		},
		
		// 上传封装 formData
		uploadimg(formData, type) {
			var url='/file/upload'
			var type = type ? type : 'post';
			var timestep = new Date().getTime();
			var token = sessionStorage.getItem('token');
			var tenantid = sessionStorage.getItem('tenantid');
			var cardid = sessionStorage.getItem('cardid');
			var signStr = this.getSignStr(url, tenantid, timestep);
		
			formData.append('timestep', timestep);
			formData.append('sign', md5(signStr));
			formData.append('tenantid', tenantid);
			formData.append('cardid', cardid);
			formData.append('token', token);
			return new Promise((resolve, reject)=>{
				this.$serve({
					url: '/file/upload',
					data: formData,
					headers:{'Content-Type':'multipart/form-data'},
					method:type
				}).then(res=>{resolve(res.data);})
			});
		},
		
		
		// 授权
		authorization(data){
			if(this.loginouttime){
				this.loginouttime=false
				if (data.status == 'loginout'){
					this.logout();
					// this.$confirm('登录已失效，前往登录？', '提示', {
					//          distinguishCancelAndClose: true,
					//          confirmButtonText: '去登陆',
					//          cancelButtonText: '取消'
					// }).then(() => {
					// 	this.$router.push({path:'/login'})
					// }).catch(action => {
						
					// });
					
					this.$notify({title: '提示',message: '登录已失效,请重新登录',type: 'error',duration:3000});
					setTimeout(()=>{this.loginouttime=true;this.$router.push({path:'/login'})}, 3000);
				} else {
					// this.$notify({title: '提示',message: data.message,type: 'success',duration:800});
				}
			}
		},
	
	
		downloadExcel(url, tradeid) {
			var timestep = new Date().getTime();
			var erpcustno = sessionStorage.getItem('erpcustno');
			var tid = sessionStorage.getItem('tenantid');
			var signStr = this.getSignStr(url, tid, timestep);
			var token = sessionStorage.getItem('token');
			var cardid = sessionStorage.getItem('cardid');
			var sign = md5(signStr);
			var url = this.serve + url + "?tenantid=".concat(tid, "&erpcustno=").concat(erpcustno, "&tradeid=").concat(tradeid,
				"&timestep=").concat(timestep, "&sign=").concat(sign,"&token=").concat(token,"&cardid=").concat(cardid);
			return url; 
		},
		
		
		// 完成订单
		wcOrders(tradeId,callback) {
			var _this2 = this;
			this.$confirm('请确认订单是否完成！', '订单完成提示', {
				confirmButtonText: '确定',
				showCancelButton: true,
				closeOnClickModal: false,
				customClass:this.color,
				callback:function(action){
					if(action=='confirm'){
						var Url = '/trade/finish';
						var data = {tradeid: tradeId};
						_this2.requset(Url, data).then(res=> {
							if(res.status=="success")_this2.$notify({title: '成功',message: '确认成功',type: 'success'});
							callback()
						});
						
					}
				}
			})
		},
		
		
		//  /memberaddressfront/credit  查询信用额度
		credit(erpcustno) {
			return new Promise((resolve, reject)=>{
				this.requset('/b2bmembercard/credit', {}).then(res=>{
						sessionStorage.setItem('credit', JSON.stringify(res.data));
						resolve(res.data);
				});
			})
		},
		
		
		// 支付宝支付
		Alipay(tradeid) {
			console.log(tradeid)
			var cardid = sessionStorage.getItem('cardid');
			var paydata = {
				tradeid: tradeid,
				platformid: 3,
				cardid: cardid,
				pay: 'pay',
				paytype: 'alipay',
			};
			console.log('支付宝支付',paydata)
			this.requset('/alipay/tradepay', paydata,{met:'pay'}).then(alipayres=> {
				
			});
		},
		
		// 银联支付
		unionpay(tradeid) {
			console.log(tradeid)
			var cardid = sessionStorage.getItem('cardid');
			var paydata = {
				tradeid: tradeid,
				platformid: 3,
				cardid: cardid,
				pay: 'pay',
				paytype: 'unionpay',
			};
			console.log('支付宝支付',paydata)
			this.requset('/unionpay/tradepay', paydata,{met:'pay'}).then(e=> {
				window.open(e.data.code_img_url,"_back");
			});
		},
		
		

		//获取回到顶部按钮的ID
		Backtop() {
			var obtn = document.getElementById('return_top'); //获取回到顶部按钮的ID
			var clientHeight = document.documentElement.clientHeight; //获取可视区域的高度
			var clientWidth = (document.documentElement.clientWidth - 1200) / 2; //获取可视区域的高度
			var timer = null; //定义一个定时器
			var isTop = true; //定义一个布尔值，用于判断是否到达顶部
			window.onscroll = function() {
				//滚动条滚动事件
				// var bodyheight = $(document.body).height(); //获取的高度
				// var Backtopwidth = clientWidth + 1200 + 30
				//获取滚动条的滚动高度
				var osTop = document.documentElement.scrollTop || document.body.scrollTop; //主要用于判断当 点击回到顶部按钮后 滚动条在回滚过程中，若手动滚动滚动条，则清除定时器
				if (!isTop)clearInterval(timer);
				isTop = false;
			};

			if(obtn)obtn.onclick = function() {
				//回到顶部按钮点击事件
				//设置一个定时器
				timer = setInterval(function() {
					//获取滚动条的滚动高度
					var osTop = document.documentElement.scrollTop || document.body.scrollTop; //用于设置速度差，产生缓动的效果
					var speed = Math.floor(-osTop / 6);
					document.documentElement.scrollTop = document.body.scrollTop = osTop + speed;
					isTop = true; //用于阻止滚动事件清除定时器
		
					if (osTop == 0) {
						clearInterval(timer);
					}
				}, 30);
			};
		},
		
		
		//获取品牌数据列表POST 开发完成 /goodsfront/hotbrands POST /b2bgoods/brands
		gethotbrands: function gethotbrands(query) {
			var Self = this;
			var Url = '/b2bgoods/hotbrands'; 
			
			var data = {query: query};
			return new Promise((resolve, reject)=>{
				this.requset(Url, data).then(function(data) {
					if (data.status == "success") {
						if (data.data.length > 11) {
							resolve( data.data.splice(0, 12))
						} else {
							resolve( data.data)
						}
					}
				});
			})
		},
		
		// 获取新闻列表
		getnewslist(){
			var article= ['HYXW', 'FLFG', 'ZSFX', 'GYWM', 'XSBZ', 'SHFW', 'ZFFS', 'QYJS', 'LXWM','SCXW']
			var reqdata = {
				categoryno: article.join(','),
				pageno: 1,
				pagesize: 10,
				version:2
			};
			console.log('this.$route',this.$route)
			var newslist=sessionStorage.getItem('newslist')
			if(newslist&&this.$route.path!='/'){
				newslist=JSON.parse(newslist)
				this.$store.dispatch('PUBLIC',{type:'newslist',res: newslist})
			}else{
				return new Promise((resolve, reject)=>{
					this.requset('/kbhelper/findarticlebycategory', reqdata).then((data)=> {
						sessionStorage.setItem('newslist', JSON.stringify(data.data));
						this.$store.dispatch('PUBLIC',{type:'newslist',res: data.data})
						resolve(data.data)
					});
				})
			}
		},
		
		islogin(){
			if(!this.$store.state.app.token) {
				this.$message({showClose: true,message: '请先登录',type: 'warning'});
				return false
			}
			return true
		},
		
		
	
		
		
		
		
	}

}

export default mixin
