<template>
	<div class="">
		<span  v-if="$store.state.app.token">
			<span>￥</span>
			<span class="f-20 bold" :class="'f-'+size">{{$store.state.app.token?decimal(0)+'.':''}}</span>
			<span>{{decimal(1)}}</span>
		</span>
		<span v-else class="f-16">价格登录后可见</span>
	</div>
</template>

<script>
	export default {
		data(){
			return{}
		},
		props:['price','size'],
		
		methods:{
			decimal(k){
				let price=this.price.toFixed(2)
				price=price.split('.')
				return price[k]
			}
		}
	}
</script>

<style>
</style>