<template>
	<div :class="color" class="theme">
	  <div id="app" >
		   <router-view/>
	  </div>
	</div>
</template>

<script>


export default {
  name: 'App',   
  
   mounted() {  
       // 创建cnzz统计js
      // const script = document.createElement('script')
      // script.src = 'https://s9.cnzz.com/z_stat.php?id=1279605418&web_id=1279605418'
      // script.language = 'JavaScript'
      // document.body.appendChild(script)
    },
	
    watch: {   
		
		
   //    '$route': {
   //      handler(to, from) {
			// var tenantid = sessionStorage.getItem('tenantid');
			// setTimeout(() => { //避免首次获取不到window._czc
			// 	if (window._czc) {
			// 	  let location = window.location;
			// 	  let contentUrl = location.pathname + location.hash;
			// 	  let refererUrl = '/';
			// 	  console.log('location',location)
			// 	  console.log('contentUrl',contentUrl)
				  
			// 	  // 用于发送某个URL的PV统计请求，
			// 	  window._czc.push(['_setCustomVar','租户id',tenantid,'1000',])
			// 	  window._czc.push(['_trackPageview', contentUrl, refererUrl])
			// 	  window._czc.push(["_setAutoPageview", false]);
			// 	}
			// }, 300)
   //      },
   //      immediate: true  // 首次进入页面即执行
   //    }
    }
}
</script>

<style>
#app {
  /* font-family:PingFang SC Bold,PingFang SC,Helvetica Neue,Helvetica,Arial,sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
div, ul, li{
    box-sizing: border-box;
}
.hover-animation {
    transition: opacity .3s ease-out;
    &:hover {
        opacity: .7;
    }
}

.module-name-tip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    padding: 8px 15px;
    background: rgba(0,0,0,0.8);
    color: #FFFFFF;
}
.el-dialog__title{color: #FFFFFF !important;}

@import url("style/goods/goodslist.css");
@import url("style/base.css");
@import url("./style/user/register.css");
@import url("style/index/about.css");
@import url("style/index/activityarea.css");
@import url("style/index/home.css");
@import url("style/index/introduce.css");
@import url("style/index/newfind.css");
@import url("style/public/headerfoot.css");
@import url("style/news/newslist.css");
@import url("style/news/newsDetail.css");
@import url("style/goods/cart.css");
@import url("style/goods/details.css");
@import url("style/member/member.css");
@import url("style/member/memberBack.css");
@import url("style/member/memberorderinfo.css");
@import url("./style/member/memberProposal.css");
@import url("./style/member/collect.css");
@import url("./style/config.scss");
@import url("./style/icon/iconfont.css");
@import url("./page/edit/demo-common/css/variable.css");


</style>
